<body>
    <div>
        <h2 style="text-align: center"><strong>MŰKÖDÉS</strong></h2>
        <div class="container" style=" grid-template-columns: 8fr 4fr;
        column-gap: 5px;">
            <div class="pad">
                <p style="text-align: center;"><strong>A Netfaktor működése</strong></p>
                <ul class="margin-ul">
                    <li>A Netfaktor egy online platform, amely összeköti a lejárt követelésekkel rendelkező eladókat a befektetőkkel és faktorcégekkel. A platform célja, hogy megkönnyítse a követelések gyors és hatékony értékesítését, és a befektetők számára
                        pedig vonzó befektetési lehetőségeket kínáljon.</li>
                </ul>
                <p style="text-align: center;"><strong>Felhasználási feltételek:</strong></p>
                <ul class="margin-ul">
                    <li>A rendszerhez kizárólag szerződött/regisztrált partnerek férhetnek hozzá. Jelenleg a rendszerhez 20 eladó (bank, közüzem, távközlési társaság, stb) és 107 MNB engedéllyel rendelkező faktortársaság fér hozzá (akik eladók is lehetnek.</li>
                    <li>A rendszer használatához internetelérése és böngésző használat szükséges.</li>
                </ul>
                <p style="text-align: center;"><strong>Követelések meghirdetése:</strong></p>
                <ul class="margin-ul">
                    <li>A hitelezők egyedi követeléseiket és követeléscsomagjaikat is meghirdethetik a platformon.</li>
                    <li>Célszerű Excel táblázatban összesíteni a és közzé tenni követeléseket (elektronikus adatszoba) a meghirdetés előtt.</li>
                    <li>A hitelezők bármilyen releváns adatot feltüntethetnek a követelésekről.</li>
                </ul>
            </div>
            <div style="text-align: center; width: 100%;">
                <img class=" imgkep imagewave " src="assets/img/undraw_business_deal_re_up4u.svg " alt="Eladóknak ">
            </div>
        </div>

        <div class="blue-bg ">
            <div class="container " style="grid-template-columns: 4fr 8fr; column-gap: 5px; ">
                <div style="text-align: center; width: 100%;">
                    <img class="imgkep imagewave" style="width: 50%;" src="assets/img/undraw_choose_re_7d5a.svg " alt="Eladóknak ">
                </div>
                <div class="pad ">
                    <p style="text-align: center;"><strong>Eladói korlátozások:</strong></p>
                    <ul>
                        <li>Az eladó korlátozhatja a követeléseihez hozzáférők körét.</li>
                        <li>Dönthet úgy, hogy egy adott faktor nem ismerheti meg a követeléseit.</li>
                        <li>Egyedi követelés szintjén is meghatározhatja, kiket hív meg az árverésre.</li>
                    </ul>

                    <p style="text-align: center;"><strong>Értesítések:</strong></p>
                    <ul>
                        <li>Az eladó korlátozhatja a követeléseihez hozzáférők körét.</li>
                        <li>Dönthet úgy, hogy egy adott faktor nem ismerheti meg a követeléseit.</li>
                        <li>Egyedi követelés szintjén is meghatározhatja, kiket hív meg az árverésre.</li>
                    </ul>

                    <p style="text-align: center;"><strong>Licitálás / Döntéshozatal:</strong></p>
                    <ul>
                        <li>
                            A hitelező kiválaszthatja az értékesítési módot (pl. pályázatos hirdetés, vaklicit, normál licit, TOP5 licit, japán licit, árejtés, stb.).
                        </li>
                        <li>
                            A licit időtartama szabadon meghatározható.
                        </li>
                        <li>
                            A licit lépcső beállítható, a rendszer javaslatot tesz a beállítható értékekre, de a hitelező szabadon módosíthatja azokat.
                        </li>
                        <li>
                            A hitelező a beérkezett legmagasabb ajánlat elfogadásáról és elutasításáról dönthet. Nincs kötelezettség a legmagasabb ajánlat elfogadására és szerződéskötésre.
                        </li>
                    </ul>

                    <p style="text-align: center;"><strong>Riportkészítés:</strong></p>
                    <ul>
                        <li>
                            A Netfaktor minden üzleti információt tárol.
                        </li>
                        <li>
                            A lezárt követelésekre kapott legjobb ajánlatokat bármikor visszakeresheti és ellenőrizheti a hitelező.
                        </li>
                        <li>
                            A program riportolási és listázási lehetőségei szabadon szerkeszthetők.
                        </li>
                    </ul>

                </div>
            </div>
        </div>

    </div>
</body>