<div class="cont" id="szolgaltatas" snInViewport (inViewportChange)="onInViewportChange($event)">
    <div class="innercont" style="padding-bottom: 100px;">
        <div class="h-tags">
            <!--<h2>Esettanulmányok</h2>
          <h2>Netfaktor működése, szolgáltatásai</h2>-->
        </div>
        <!-- <h2>On-line Követeléspiac</h2> -->
        <!-- <div class="swiper-button-prev"></div> -->
        <div class="swiper-container" [swiper]="config">
            <div class="swiper-wrapper">

                <div *ngFor="let slide of slides" class="swiper-slide">

                    <div></div>
                    <div class="text">
                        <h2 class=szoveg>{{slide.title}}</h2>

                        <p class="slider-p">{{slide.desc}}</p>
                        <a href="/kapcsolat">További információ</a>

                    </div>
                    <div class="imagecont">
                        <div class="image" style="background-image: url('{{slide.img}}');">
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- <div class="swiper-button-next"></div> -->
    </div>
</div>
